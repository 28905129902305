.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
  padding: 0px 12px;
  cursor: pointer;
}
.editable-row {
  .ant-table-cell {
    padding-block: 6px !important;
  }
  .editable-cell-value-wrap {
    padding: 6px 12px 4px;
    min-height: 24px;
  }
  &:hover {
    .editable-cell-value-wrap {
      padding: 5px 11px 3px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
    }
  }
}
