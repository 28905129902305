.footerContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.buttonWithIcon {
  height: 40px;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: medium;
}